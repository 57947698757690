<template>
    <div id="cpanel" v-if="showContent">
        <div id="cpanel_header">
            <img src="/images/logo.svg" />
            <span id="cpanel_header_link_logout" @click.stop="logout">Выход</span>
        </div>
        <div id="cpanel_body">
            <div id="cpanel_body_left">
                <span class="cpanel_body_left_menuItem"
                      :class="{'cpanel_body_left_menuItem_active': isPage(PAGE_ACTIVATE_LIST)}"
                      @click.stop="showPage(PAGE_ACTIVATE_LIST)"
                      >Активация</span>
                <span class="cpanel_body_left_menuItem"
                      :class="{'cpanel_body_left_menuItem_active': isPage(PAGE_LIST_EDIT)}"
                      @click.stop="showPage(PAGE_LIST_EDIT)"
                      >Редактирование</span>
                <span class="cpanel_body_left_menuItem"
                      :class="{'cpanel_body_left_menuItem_active': isPage(PAGE_LIST_USERS)}"
                      @click.stop="showPage(PAGE_LIST_USERS)"
                      >Пользователи ЛК 3</span>
                <span class="cpanel_body_left_menuItem"
                      :class="{'cpanel_body_left_menuItem_active': isPage(PAGE_STAT)}"
                      @click.stop="showPage(PAGE_STAT)"
                      >Статистика</span>
                <span class="cpanel_body_left_menuItem"
                      :class="{'cpanel_body_left_menuItem_active': isPage(PAGE_STAT_ACCOUNT)}"
                      @click.stop="showPage(PAGE_STAT_ACCOUNT)"
                      >Статистика по аккаунтам</span>
            </div>
            <div id="cpanel_body_right">
                <div class="page" v-show="isPage(PAGE_ACTIVATE_LIST)">
                    <div
                        v-for="(item, index) in list"
                        :key="index" 
                        class="row"
                        >
                        <div class="cell_firstName">{{ item.first_name }}</div>
                        <div class="cell_lastName">{{ item.last_name }}</div>
                        <div class="cell_email">{{ item.email }}</div>
                        <div class="cell_phone">{{ item.phone }}</div>
                        <div class="cell_inn">{{ item.inn }}</div>
                        <div class="cell_typeAccount">{{ item.type_account == 2 ? 'Производитель' : 'Дистрибьютор' }}</div>
                        <div class="cell_datetime">{{ item.createDateJsText }}</div>
                        <div class="cell_btns">
                            <button class="btn" @click.stop="activateAccount(item.id)">Активировать аккаунт</button>
                        </div>
                    </div>
                    <div class="cpanel_body_empty" v-if="list.length == 0">
                        Список пуст
                    </div>
                </div>
                <div class="page" v-show="isPage(PAGE_LIST_EDIT)">
                    <div id="page_2_find">
                        <div id="page_2_find_by_inn">
                            <input placeholder="Поиск по ИНН..." 
                                   v-mask="'#### #### ##'"
                                   v-model="serachByInn.value"
                                   @keyup="onSearchByInn"/>
                            <img src="/images/clear-input.svg" @click.stop="clearSearchByInn" />
                        </div>
                    </div>
                    
                    <template v-if="list2.length > 0">
                        <table class="page_2_listTable">
                            <tr class="page_2_listTable_header">
                                <td>Имя</td>
                                <td>Фамилия</td>
                                <td>Email</td>
                                <td>Телефон</td>
                                <td>Инн</td>
                                <td>Тип аккаунта</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr
                                v-for="(item, index) in list2"
                                :key="index"
                                class="page_2_listTable_row"
                                >
                                <td class="cell_firstName">{{ item.first_name }}</td>
                                <td class="cell_lastName">{{ item.last_name }}</td>
                                <td class="cell_email">{{ item.email }}</td>
                                <td class="cell_phone">{{ item.phone }}</td>
                                <td class="cell_inn">{{ item.inn }}</td>
                                <td class="cell_typeAccount">{{ item.type_account == 2 ? 'Производитель' : 'Дистрибьютор' }}</td>
                                <td class="cell_datetime">{{ item.createDateJsText }}</td>
                                <td class="cell_btns">
                                    <button class="btn" @click.stop="editAccount(item)">Редактировать</button>
                                </td>
                            </tr>
                        </table>
                    </template>
                    
                    <div class="cpanel_body_empty" v-if="list2.length == 0">
                        Список пуст
                    </div>
                    
                    <div v-if="list2_count_page > 1" class="pages">
                        <span
                            v-for="index in list2_count_page"
                            :key="index"
                            :class="{'page_active': list2_page + 1 == index}"
                            @click.stop="list2_page + 1 != index && getList_2(index - 1)">
                            {{ index }}
                        </span>
                    </div>
                    
                </div>
                <div class="page" v-show="isPage(PAGE_FORM_EDIT)">
                    <div id="profile_info">
                        <div id="profile_info_title">Личные данные пользователя</div>
                        <div class="profile_row">
                            <div class="profile_user_item">
                                <div class="profile_user_item_title">Фамилия</div>
                                <input class="profile_user_item_value" v-model="formEdit.lastName" />
                            </div>
                            <div class="profile_user_item">
                                <div class="profile_user_item_title">Личный E-mail</div>
                                <input class="profile_user_item_value" v-model="formEdit.email" />
                            </div>
                        </div>

                        <div class="profile_row">
                            <div class="profile_user_item">
                                <div class="profile_user_item_title">Имя</div>
                                <input class="profile_user_item_value" v-model="formEdit.firstName" />
                            </div>
                            <div class="profile_user_item">
                                <div class="profile_user_item_title">Личный телефон</div>
                                <input class="profile_user_item_value" v-mask="'+7(###) ### ## ##'" v-model="formEdit.phone" />
                            </div>
                        </div>
                        <div id="profile_info_error">{{ formEdit.error }}</div>
                        <div id="profile_info_footer">
                            <button class="btn" @click.stop="showPage(PAGE_LIST_EDIT)">Отмена</button>
                            <button class="btn" @click.stop="saveData()">Сохранить</button>
                        </div>
                    </div>
                </div>
                <div class="page" v-show="isPage(PAGE_LIST_USERS)">
                    <template v-if="list3.length > 0">
                        <table class="page_2_listTable">
                            <tr class="page_2_listTable_header">
                                <td>Имя</td>
                                <td>Фамилия</td>
                                <td>Email</td>
                                <td>Телефон</td>
                                <td>Должность</td>
                                <td>Наименование объекта</td>
                                <td>Создан</td>
                            </tr>
                            <tr
                                v-for="(item, index) in list3"
                                :key="index"
                                class="page_2_listTable_row"
                                >
                                <td class="cell_firstName">{{ item.first_name }}</td>
                                <td class="cell_lastName">{{ item.last_name }}</td>
                                <td class="cell_email">{{ item.email }}</td>
                                <td class="cell_phone">{{ item.phone }}</td>
                                <td class="cell_inn">{{ item.position }}</td>
                                <td>{{ item.object_name }}</td>
                                <td class="cell_datetime">{{ item.createDateJsText }}</td>
                            </tr>
                        </table>
                    </template>
                    
                    <div class="cpanel_body_empty" v-if="list3.length == 0">
                        Список пуст
                    </div>
                    
                    <div v-if="list3_count_page > 1" class="pages">
                        <span
                            v-for="index in list3_count_page"
                            :key="index"
                            :class="{'page_active': list3_page + 1 == index}"
                            @click.stop="list3_page + 1 != index && getListUsers(index - 1)">
                            {{ index }}
                        </span>
                    </div>
                    
                </div>
                <div class="page" v-show="isPage(PAGE_STAT)">
                    <TableStats @openStatFromModel="openStatDownloadTechnicalCertificate"/>
                </div>
                <div class="page" v-show="isPage(PAGE_STAT_DOWNLOAD_TECHNICAL_CERTIFICATE)">
                    <StatDownloadTechnicalCertificate 
                        ref="statDownloadTechnicalCertificate"
                        @close="closePageStatDownloadTC" />
                </div>
                <div class="page" v-show="isPage(PAGE_STAT_ACCOUNT)">
                    <ShowGlobalStatForAccounts />
                </div>
            </div>
                
        </div>
    </div>
</template>

<script>
    import router from '@/router'
    import * as global from '../global.js'
    import TableStats from './TableStats.vue'
    import StatDownloadTechnicalCertificate from './StatDownloadTechnicalCertificate.vue'
    import ShowGlobalStatForAccounts from './ShowGlobalStatForAccounts.vue'
    
    export default {
        name: 'Cpanel',
        title: 'Панель управления',
        components: {
            TableStats, StatDownloadTechnicalCertificate, ShowGlobalStatForAccounts
        },
        data() {
            return {
                showContent: false,
                show_page: 0,
                pagesInfo: {},
                list: [],
                list2: [],
                list3: [],
                list2_page: 0,
                list3_page: 0,
                list2_count_page: 0,
                list3_count_page: 0,
                formEdit: {
                    editId: 0,
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                },
                serachByInn: {
                    value: '',
                }
            };
        },
        created() {
            
            if( ! localStorage.token ) {
                router.push('/login');
                return;
            }
            
            this.showContent = true;
            
            this.PAGE_ACTIVATE_LIST                         = 1;
            this.PAGE_LIST_EDIT                             = 2;
            this.PAGE_FORM_EDIT                             = 3;
            this.PAGE_LIST_USERS                            = 4;
            this.PAGE_STAT                                  = 5;
            this.PAGE_STAT_DOWNLOAD_TECHNICAL_CERTIFICATE   = 6;
            this.PAGE_STAT_ACCOUNT                          = 7;
            
            this.show_page = this.PAGE_ACTIVATE_LIST;
            
            this.pagesInfo[this.PAGE_ACTIVATE_LIST] = {
                    loaded: false
                };
            this.pagesInfo[this.PAGE_LIST_EDIT] = {
                    loaded: false
                };
            this.pagesInfo[this.PAGE_LIST_USERS] = {
                    loaded: false
                };
            
            this.getList();
        },
        methods: {
            showPage(id) {
                this.show_page = id;
                
                if(    this.pagesInfo[ id ] 
                    && ! this.pagesInfo[ id ].loaded
                ) {
                    if(this.show_page == 1) {
                        this.getList();
                    }
                    else if(this.show_page == 2) {
                        this.getList_2();
                    }
                    else if(this.show_page == 4) {
                        this.getListUsers();
                    }
                }
            },
            isPage(id) {
                return this.show_page == id;
            },
            logout() {
                this.$bus.emit('showLoading');

                this.$getRequest(this.$config.urlLogout, localStorage.token)
                .then(() => {
                    this.$bus.emit('hideLoading');
                    localStorage.token = '';
                    router.push('/login');
                })
                .catch(() => {
                    this.$bus.emit('hideLoading');
                });
            },
            getList() {
                this.$bus.emit('showLoading');

                this.$getRequest(this.$config.urlGetList, localStorage.token)
                .then((data) => {
                    this.$bus.emit('hideLoading');
                    this.pagesInfo[1].loaded = true;
                    
                    for(let item of data.list) {
                        var date = new Date(item.created_at);
                        var userTimezoneOffset = date.getTimezoneOffset() * 60000;
                        item.createDateJs = new Date(date.getTime() - userTimezoneOffset);
                        item.createDateJsText = global.timeConverter(item.createDateJs.getTime());
                    }
                    
                    this.list = data.list;
                    
                    console.log(this.list);
                })
                .catch(() => {
                    this.$bus.emit('hideLoading');
                });
            },
            getList_2(page = 0) {
                this.list2_page = page;
                this.$bus.emit('showLoading');

                this.$getRequest(this.$config.urlGetList_2 + page, localStorage.token)
                .then((data) => {
                    this.$bus.emit('hideLoading');
                    this.pagesInfo[2].loaded = true;
                    
                    for(let item of data.list) {
                        var date = new Date(item.created_at);
                        var userTimezoneOffset = date.getTimezoneOffset() * 60000;
                        item.createDateJs = new Date(date.getTime() - userTimezoneOffset);
                        item.createDateJsText = global.timeConverter(item.createDateJs.getTime());
                    }
                    
                    this.list2_count_page = data.count_page;
                    this.list2 = data.list;
                })
                .catch(() => {
                    this.$bus.emit('hideLoading');
                });
            },
            getListUsers(page = 0) {
                this.list3_page = page;
                this.$bus.emit('showLoading');

                this.$getRequest(this.$config.urlGetListUsers + page, localStorage.token)
                .then((data) => {
                    this.$bus.emit('hideLoading');
                    this.pagesInfo[2].loaded = true;
                    
                    for(let item of data.list) {
                        var date = new Date(item.created_at);
                        var userTimezoneOffset = date.getTimezoneOffset() * 60000;
                        item.createDateJs = new Date(date.getTime() - userTimezoneOffset);
                        item.createDateJsText = global.timeConverter(item.createDateJs.getTime());
                    }
                    
                    this.list3_count_page = data.count_page;
                    this.list3 = data.list;
                })
                .catch(() => {
                    this.$bus.emit('hideLoading');
                });
            },
            activateAccount(id) {
                this.$bus.emit('showLoading');

                this.$postRequest(this.$config.urlActivateAccount, {
                    id: id
                }, localStorage.token)
                .then(() => {
                    this.$bus.emit('hideLoading');
                    this.getList();
                })
                .catch(() => {
                    this.$bus.emit('hideLoading');
                });
            },
            editAccount(item) {
                console.log(item);
                
                this.formEdit.editId     = item.id;
                this.formEdit.firstName  = item.first_name;
                this.formEdit.lastName   = item.last_name;
                this.formEdit.email      = item.email;
                this.formEdit.phone      = item.phone;
                this.formEdit.error      = '';
                
                this.showPage(this.PAGE_FORM_EDIT);
            },
            saveData() {
                this.formEdit.error = '';
                
                let firstName = this.formEdit.firstName.trim();
                let lastName = this.formEdit.lastName.trim();
                
                if(lastName.length < 2) {
                    this.formEdit.error = 'Не указана фамилия';
                    return;
                }
                
                if( ! global.validateEmail(this.formEdit.email) ) {
                    this.formEdit.error = 'Email указан неверно';
                    return;
                }
                
                if(firstName.length < 2) {
                    this.formEdit.error = 'Не указано имя';
                    return;
                }
                
                if( ! global.validatePhone(this.formEdit.phone) ) {
                    this.formEdit.error = 'Телефон указан неверно';
                    return;
                }
                
                this.$bus.emit('showLoading');

                this.$postRequest(this.$config.urlSaveDataAccount, {
                    id: this.formEdit.editId,
                    firstName: this.formEdit.firstName,
                    lastName: this.formEdit.lastName,
                    email: this.formEdit.email,
                    phone: this.formEdit.phone
                }, localStorage.token)
                .then(() => {
                    this.$bus.emit('hideLoading');
                    this.showPage(this.PAGE_LIST_EDIT)
                    this.getList_2();
                })
                .catch(() => {
                    this.$bus.emit('hideLoading');
                });
            },
            onSearchByInn() {
                let _inn = this.serachByInn.value.replace(/\s/g, '');
                
                if(_inn.length > 2) {
                    this.$getRequest(this.$config.urlSerachByInn + _inn, localStorage.token)
                    .then((data) => {
                        for(let item of data.list) {
                            var date = new Date(item.created_at);
                            var userTimezoneOffset = date.getTimezoneOffset() * 60000;
                            item.createDateJs = new Date(date.getTime() - userTimezoneOffset);
                            item.createDateJsText = global.timeConverter(item.createDateJs.getTime());
                        }
                        
                        this.list2 = data.list;
                    })
                    .catch(() => {
                    });
                }
                else if(_inn.length == 0) {
                    this.getList_2();
                }
            },
            clearSearchByInn() {
                this.serachByInn.value = '';
                this.getList_2();
            },
            openStatDownloadTechnicalCertificate(item) {
                this.showPage(this.PAGE_STAT_DOWNLOAD_TECHNICAL_CERTIFICATE);
                this.$refs.statDownloadTechnicalCertificate.setModelInfo(item);
                this.$refs.statDownloadTechnicalCertificate.loadList();
            },
            closePageStatDownloadTC() {
                this.showPage(this.PAGE_STAT);
            }
        }
    }
</script>

<style scoped>
    #cpanel {
        display: flex;
        flex-direction: column;
    }
    #cpanel_header {
        background: #f0f0f0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 10px;
        border-bottom: 1px solid #aaa;
    }
    #cpanel_header_link_logout {
        font-size: 12px;
        cursor: pointer;
    }
    #cpanel_header_link_logout:hover {
        text-decoration: underline;
    }
    #cpanel_body {
        display: flex;
    }
    .row {
        display: flex;
        align-items: center;
        width: 100%;
    }
    .row:hover {
        background: #f8f8f8;
    }
    .row > div {
        padding: 8px;
        font-size: 14px;
    }
    .btn {
        color: #333;
        padding: 6px 14px;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#eeeeee+0,eeeeee+100;Grey+Flat */
        background: #eeeeee; /* Old browsers */
        background: -moz-linear-gradient(top,  #eeeeee 0%, #eeeeee 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  #eeeeee 0%,#eeeeee 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  #eeeeee 0%,#eeeeee 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#eeeeee',GradientType=0 ); /* IE6-9 */
        border: 1px solid #CCC;
        border-radius: 3px;
    }
    .btn:hover {
        color: #000;
    }
    .btn:active {
        background: #cccccc;
        background: -moz-linear-gradient(top,  #cccccc 0%, #eeeeee 100%);
        background: -webkit-linear-gradient(top,  #cccccc 0%,#eeeeee 100%);
        background: linear-gradient(to bottom,  #cccccc 0%,#eeeeee 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cccccc', endColorstr='#eeeeee',GradientType=0 );
    }
    .cell_btns {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .cpanel_body_empty {
        padding: 15px;
        font-size: 14px;
    }
    
    #cpanel_body_left {
        width: 250px;
        min-height: 100vh;
        background: #eee;
        border-right: 1px solid #aaa;
        display: flex;
        flex-direction: column;
    }
    .cpanel_body_left_menuItem {
        cursor: pointer;
        padding: 0 10px;
        height: 40px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #fff;
        font-size: 14px;
    }
    .cpanel_body_left_menuItem:hover {
        background: #fefefe;
    }
    .cpanel_body_left_menuItem_active, .cpanel_body_left_menuItem_active:hover {
        background: #84d2f7;
        cursor: default;
    }
    #cpanel_body_right {
        width: 100%;
    }
    .page_2_listTable {
        width: 100%;
    }
    .page_2_listTable_header > td {
        border-bottom: 1px solid #CCC;
        padding: 5px;
        font-size: 14px;
    }
    .page_2_listTable_row > td {
        padding: 5px;
        font-size: 14px;
    }
    .page_2_listTable_row:hover > td {
        background: #f5f5f5;
    }
    
    
    #profile_info {
        display: flex;
        flex-direction: column;
        margin-left: 40px;
        margin-top: 25px;
    }
    #profile_info_title {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 20px;
        color: #454A4D;
        margin-bottom: 28px;
    }
    .profile_user_item {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #D9D9DA;
        width: 270px;
    }
    .profile_user_item_title {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        color: rgba(71, 75, 80, 0.5);
    }
    .profile_user_item_value {
        border: none;
        display: block;
        margin-top: 11px;
        margin-bottom: 10px;
        font-size: 15px;
    }
    .profile_row {
        display: flex;
        margin-left: 10px;
        margin-top: 10px;
    }
    .profile_row .profile_user_item:first-child {
        margin-right: 47px;
    }
    #profile_info_footer {
        margin: 30px 0 50px 8px;
        display: flex;
        align-items: center;
    }
    #profile_info_footer > .btn {
        display: inline-block;
        margin-right: 15px;
    }
    #profile_info_error {
        margin-top: 15px;
        margin-left: 10px;
        color: red;
        font-size: 12px;
        min-height: 20px;
    }
    #page_2_find {
        margin-bottom: 20px;
        border-bottom: 1px solid #eee;
        padding: 8px;
    }
    #page_2_find_by_inn {
        position: relative;
        width: 250px;
    }
    #page_2_find_by_inn > input {
        width: 100%;
        border: 1px solid #CCC;
        padding: 5px;
        font-size: 14px;
    }
    #page_2_find_by_inn > img {
        position: absolute;
        right: 5px;
        top: 3px;
        cursor: pointer;
        opacity: 0.1;
    }
    #page_2_find_by_inn > img:hover {
        opacity: 0.3;
    }
    .row .cell_typeAccount {
        flex-grow: 1;
    }
    .cell_datetime {
        color: #555;
        font-size: 14px;
    }
    .pages {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 10px 0;
    }
    .pages > span {
        font-size: 14px;
        display: inline-block;
        padding: 2px 5px;
        cursor: pointer;
        margin: 0 2px;
    }
    .pages > .page_active {
        cursor: default;
        font-weight: bold;
    }
</style>