//import { createI18n } from 'vue-i18n'
import { createI18n } from 'vue-i18n/index'
import { languages } from './localization/index.js'

const messages = Object.assign(languages)

localStorage.lang = 'ru';

/*if(! localStorage.lang) {
    if (navigator && /^ru\b/.test(navigator.language)) {
        localStorage.lang = 'ru';
    }
    else {
        localStorage.lang = 'en';
    }
}*/

// 2. Create i18n instance with options
const i18n = createI18n({
  locale: localStorage.lang, // set locale
  fallbackLocale: 'ru', // set fallback locale
  messages, // set locale messages
  
});

export default i18n;