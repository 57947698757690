import { createRouter, createWebHistory } from 'vue-router'

import Login from '@/components/Login.vue'
import Cpanel from '@/components/Cpanel.vue'
import NotFound from '@/components/NotFound.vue'

const routerHistory = createWebHistory()
const router = createRouter({
  history: routerHistory,
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/cpanel',
      name: 'Cpanel',
      component: Cpanel
    },
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound }
  ]
});

export default router;