import router from '@/router'
import { toast } from './toast.js'

let host_main  = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8001/' : 'https://api.qrdata.tech/';
let host  = process.env.NODE_ENV === 'development' 
            ? 'http://127.0.0.1:8001/api/admin/' 
            : 'https://api.qrdata.tech/api/admin/';

export const g_config = {
    urlMain: host_main,
    urlLogin: host + 'login',
    urlLogout: host + 'logout',
    urlGetList: host + 'get-list',
    urlGetList_2: host + 'get-list-2/',
    urlActivateAccount: host + 'activate-account',
    urlSaveDataAccount: host + 'save-data-account',
    urlSerachByInn: host + 'search-by-inn/',
    urlGetListUsers: host + 'get-list-users/',
    urlGetListStats: host + 'get-list-stats',
    urlGetListStatsDownloadTechnicalCertificate: host + 'get-list-stats-download-technical-certificate/',
    urlGetGlobalStats: host + 'get-global-list-stats/',
}


let errorMessage = {
    'error-login-or-pass': 'Логин или пароль указаны неверно'
};

/**
 * Обработка ошибки апи, которую вернул сам сервер, в json ответа
 */
export const apiErrorHandling = (data) => {
    console.log(data);

    if(data.key == "unauthorized")
    { 
        toast.toast( { type: 'text', value: "Пользователь не авторизован" }, { settings: { duration: 5300 } });
        router.push('/login');
        return;
    }
    
    toast.toast( { type: 'text', value: errorMessage[data.key] }, { settings: { duration: 5300 } });
};

/**
 * Обработка ошибки запроса к апи
 */
const errorResponseHandling = (err, method, url, text, request_data = {}) => {
    
    console.log(err, '-', err.name, '-', err.message, method, url, text, request_data);
    
    let printMessage = "Ошибка обработки запроса";
    
    if (err instanceof TypeError) {
        if(err.message.indexOf("NetworkError") != -1) {
            printMessage = "Ошибка сети";
        }
    }
    /*else if (err instanceof SyntaxError) {
        
        fetch(g_config.urlSendError, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization': 'Bearer ' + localStorage.token,
              'pragma': 'no-cache',
              'cache-control': 'no-cache',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                type: method,
                url: url,
                data:  navigator.userAgent 
                        + ' \n' + window.location 
                        + ' \n' + JSON.stringify(request_data) 
                        + ' \n' + text
            })
        });
    }*/

    toast.toast( { type: 'text', value: printMessage }, { settings: { duration: 5300 } });
};

/**
 * Запрос POST
 * 
 * @param {type} url
 * @param {type} request_data
 * @param {type} x_csrf_token
 * @returns {unresolved}
 */
export const g_postRequest = async (url = '', request_data = {}, token = '') => {
    
    let text = '';
    
    try {
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Accept-Language': localStorage.lang,
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=utf-8',
              'X-Token-Secure': token,
              'pragma': 'no-cache',
              'cache-control': 'no-cache',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(request_data)
        });
        
        text = await response.text(); // Parse it as text
        const data = JSON.parse(text); // Try to parse it as json
        
        if(data.success === false) {
            apiErrorHandling(data);
            return Promise.reject({type_error: 'api', data: data});
        }
        
        return data;
    }
    catch(err) {
        errorResponseHandling(err, 'POST', url, text, request_data);
        return Promise.reject({type_error: 'other', data: err});
    }
};

/**
 * Запрос POST
 * 
 * @param {type} url
 * @param {type} request_data (new FormData())
 * @param {type} x_csrf_token
 * @returns {unresolved}
 */
export const g_postRequest2 = async (url = '', request_data, token) => {
    
    let text = '';
    
    try {
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Accept-Language': localStorage.lang,
              'Accept': 'application/json',
              'X-Token-Secure': token,
              'pragma': 'no-cache',
              'cache-control': 'no-cache',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: request_data
        });
    
        text = await response.text(); // Parse it as text
        const data = JSON.parse(text); // Try to parse it as json
        
        if(data.success === false) {
            apiErrorHandling(data);
            return Promise.reject({type_error: 'api', data: data});
        }
        
        return data;
    }
    catch(err) {
        errorResponseHandling(err, 'POST', url, text, request_data);
        return Promise.reject({type_error: 'other', data: err});
    }
};

/**
 * Запрос PUT
 * 
 * @param {type} url
 * @param {type} request_data
 * @param {type} x_csrf_token
 * @returns {unresolved}
 */
export const g_putRequest = async (url = '', request_data = {}, token = '') => {
    
    let text = '';
    
    try {
        const response = await fetch(url, {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Accept-Language': localStorage.lang,
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=utf-8',
              'X-Token-Secure': token,
              'pragma': 'no-cache',
              'cache-control': 'no-cache',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(request_data)
        });
    
        text = await response.text(); // Parse it as text
        const data = JSON.parse(text); // Try to parse it as json
        
        if(data.success === false) {
            apiErrorHandling(data);
            return Promise.reject({type_error: 'api', data: data});
        }
        
        return data;
    }
    catch(err) {
        errorResponseHandling(err, 'PUT', url, text, request_data);
        return Promise.reject({type_error: 'other', data: err});
    }
};

/**
 * Запрос GET
 * 
 * @param {type} url
 * @param {type} token
 * @returns {unresolved}
 */
export const g_getRequest = async (url = '', token = '') => {
    
    let text = '';
    
    try {
        const response = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Accept-Language': localStorage.lang,
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=utf-8',
              'X-Token-Secure': token,
              'pragma': 'no-cache',
              'cache-control': 'no-cache',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        });
        
        text = await response.text(); // Parse it as text
        const data = JSON.parse(text); // Try to parse it as json
        
        if(data.success === false) {
            apiErrorHandling(data);
            return Promise.reject({type_error: 'api', data: data});
        }
        
        return data;
    }
    catch(err) {
        errorResponseHandling(err, 'GET', url, text);
        return Promise.reject({type_error: 'other', data: err});
    }
};


/**
 * Запрос DELETE
 * 
 * @param {type} url
 * @param {type} token
 * @returns {unresolved}
 */
export const g_deleteRequest = async (url = '', token = '') => {
    
    let text = '';
    
    try {
        const response = await fetch(url, {
            method: 'DELETE',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Accept-Language': localStorage.lang,
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=utf-8',
              'X-Token-Secure': token,
              'pragma': 'no-cache',
              'cache-control': 'no-cache',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        });
    
        text = await response.text(); // Parse it as text
        const data = JSON.parse(text); // Try to parse it as json
        
        if(data.success === false) {
            apiErrorHandling(data);
            return Promise.reject({type_error: 'api', data: data});
        }
        
        return data;
    }
    catch(err) {
        errorResponseHandling(err, 'DELETE', url, text);
        return Promise.reject({type_error: 'other', data: err});
    }
};

export const timeConverter = (UNIX_timestamp) => {
    let a = new Date(UNIX_timestamp * 1000);
    let year = a.getFullYear();
    let month = '' + (a.getMonth() + 1);
    let date = '' + a.getDate();
    let hour = '' + a.getHours();
    let min = '' + a.getMinutes();

    if (month.length < 2) month = '0' + month;
    if (date.length < 2)  date = '0' + date;
    if (hour.length < 2)  hour = '0' + hour;
    if (min.length < 2)   min = '0' + min;

    return date + '.' + month + '.' + year + ' ' + hour + ':' + min;
}

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const validatePhone = (phone) => {
    return /\+7\(\d{3}\)\s\d{3}\s\d{2}\s\d{2}/.test(phone);
};

/**
 * 
 * @param {date} a
 * @returns {dd.mm.yyyy}
 */
export const dateToString = (a) => {
    let year = a.getFullYear();
    let month = '' + (a.getMonth() + 1);
    let date = '' + a.getDate();

    if (month.length < 2) month = '0' + month;
    if (date.length < 2)  date = '0' + date;

    return date + '.' + month + '.' + year;
}

/**
 * 
 * @param {type} string date dd.mm.yyyy
 * @returns {Date}
 */
export const strToDate = (strdate) => {
    
    const re = /^\d{2}\.\d{2}\.\d{4}/
    if( ! re.test(strdate) ) {
        return null;
    }
    
    let dateParts = strdate.split(".");
    return new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
}

export const convertDateTime = (dateTime) => {
    dateTime = dateTime.split(" ");

    var date = dateTime[0].split("-");
    var yyyy = date[0];
    var mm = date[1]-1;
    var dd = date[2];

    var time = dateTime[1].split(":");
    var h = time[0];
    var m = time[1];

    return new Date(yyyy, mm, dd, h, m);
}