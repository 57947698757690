<template>
    <div id="table_stat_c">
        
        <div class="table_stat_c_item">
            <span class="table_stat_c_item_title">Серийный номер: </span>
            <div class="inputWithClearBtn" id="searchBySerialNumber">
                <input placeholder="Поиск по номеру..."
                       v-model="serach_value"
                       @keyup="getList(0)"/>
                <img src="/images/clear-search-input.svg" @click.stop="clearSearch" />
            </div>
        </div>
        
        <div class="table_stat_c_item">
            <span class="table_stat_c_item_title">Период отбора, по дате с: </span>
            <div :class="{'table_stat_c_periodDates_haveError': periodDates.error }">
                <span class="table_stat_c_periodDates" @click.stop="openCalendar(1)">
                    {{ periodDates.start_date == '' ? '__.__.____' : periodDates.start_date }}
                    <img src="/images/clear-search-input.svg" 
                         class="table_stat_c_periodDates_clearBtn"
                         @click.stop="clearStartDate" />
                    <div class="table_stat_c_periodDates_popupCalendar"
                        :class="{'table_stat_c_periodDates_popupCalendar-show': periodDates.showCalendar_1 }">
                       <Calendar v-if="periodDates.showCalendar_1" 
                                 :selectDate="periodDates.selectDate" 
                                 :indexField="periodDates.indexCalendar" 
                                 @selectDay="setDateInCalendar" />
                   </div>
                </span>
                <span class="table_stat_c_item_title"> до: </span>
                <span class="table_stat_c_periodDates" @click.stop="openCalendar(2)">
                    {{ periodDates.end_date == '' ? '__.__.____' : periodDates.end_date }}
                    <img src="/images/clear-search-input.svg" 
                         class="table_stat_c_periodDates_clearBtn"
                         @click.stop="clearEndDate" />
                    <div class="table_stat_c_periodDates_popupCalendar"
                        :class="{'table_stat_c_periodDates_popupCalendar-show': periodDates.showCalendar_2 }">
                       <Calendar v-if="periodDates.showCalendar_2" 
                                 :selectDate="periodDates.selectDate" 
                                 :indexField="periodDates.indexCalendar" 
                                 @selectDay="setDateInCalendar" />
                   </div>
                </span>
            </div>
        </div>
        
        <table id="tableStat" v-if="list.length > 0">
            <tr class="tableStat_header">
                <td>Изображение</td>
                <td>
                    <span>Название модели</span>
                    <div class="sort_icon" 
                        :class="{
                           'sort_icon_up' : sort.column == SORT_MODEL__NAME && sort.type == SORT_TYPE_ASC,
                           'sort_icon_down' : sort.column == SORT_MODEL__NAME && sort.type == SORT_TYPE_DESC,
                           'sort_icon_none' : sort.column != SORT_MODEL__NAME
                        }"
                        @click="changeSortColumn(SORT_MODEL__NAME)"
                        ></div>
                </td>
                <td>
                    <span>Серийный номер</span>
                    <div class="sort_icon" 
                        :class="{
                           'sort_icon_up' : sort.column == SORT_SERIAL_NIMBER && sort.type == SORT_TYPE_ASC,
                           'sort_icon_down' : sort.column == SORT_SERIAL_NIMBER && sort.type == SORT_TYPE_DESC,
                           'sort_icon_none' : sort.column != SORT_SERIAL_NIMBER
                        }"
                        @click="changeSortColumn(SORT_SERIAL_NIMBER)"
                        ></div>
                </td>
                <td>
                    <span>Открыт</span>
                    <div class="sort_icon" 
                        :class="{
                           'sort_icon_up' : sort.column == SORT_COLUMN__OPEN && sort.type == SORT_TYPE_ASC,
                           'sort_icon_down' : sort.column == SORT_COLUMN__OPEN && sort.type == SORT_TYPE_DESC,
                           'sort_icon_none' : sort.column != SORT_COLUMN__OPEN
                        }"
                        @click="changeSortColumn(SORT_COLUMN__OPEN)"
                        ></div>
                </td>
                <td>
                    <span>Руководство по эксплуатации</span>
                    <div class="sort_icon" 
                        :class="{
                           'sort_icon_up' : sort.column == SORT_COLUMN__DOWNLOAD_MANUAL && sort.type == SORT_TYPE_ASC,
                           'sort_icon_down' : sort.column == SORT_COLUMN__DOWNLOAD_MANUAL && sort.type == SORT_TYPE_DESC,
                           'sort_icon_none' : sort.column != SORT_COLUMN__DOWNLOAD_MANUAL
                        }"
                        @click="changeSortColumn(SORT_COLUMN__DOWNLOAD_MANUAL)"
                        ></div>
                </td>
                <td>
                    <span>Технический паспорт</span>
                    <div class="sort_icon" 
                        :class="{
                           'sort_icon_up' : sort.column == SORT_COLUMN__TECHNICAL_CERTIFICATE && sort.type == SORT_TYPE_ASC,
                           'sort_icon_down' : sort.column == SORT_COLUMN__TECHNICAL_CERTIFICATE && sort.type == SORT_TYPE_DESC,
                           'sort_icon_none' : sort.column != SORT_COLUMN__TECHNICAL_CERTIFICATE
                        }"
                        @click="changeSortColumn(SORT_COLUMN__TECHNICAL_CERTIFICATE)"
                        ></div>
                </td>
                <td>
                    <span>Доп. документы</span>
                    <div class="sort_icon" 
                        :class="{
                           'sort_icon_up' : sort.column == SORT_COLUMN__OTHER_DOCS && sort.type == SORT_TYPE_ASC,
                           'sort_icon_down' : sort.column == SORT_COLUMN__OTHER_DOCS && sort.type == SORT_TYPE_DESC,
                           'sort_icon_none' : sort.column != SORT_COLUMN__OTHER_DOCS
                        }"
                        @click="changeSortColumn(SORT_COLUMN__OTHER_DOCS)"
                        ></div>
                </td>
                <td>
                    <span>Создан</span>
                    <div class="sort_icon" 
                        :class="{
                           'sort_icon_up' : sort.column == SORT_COLUMN__CREATED_AT && sort.type == SORT_TYPE_ASC,
                           'sort_icon_down' : sort.column == SORT_COLUMN__CREATED_AT && sort.type == SORT_TYPE_DESC,
                           'sort_icon_none' : sort.column != SORT_COLUMN__CREATED_AT
                        }"
                        @click="changeSortColumn(SORT_COLUMN__CREATED_AT)"
                        ></div>
                </td>
            </tr>
            <tr
                v-for="(item, index) in list"
                :key="index"
                class="tableStat_row"
                >
                <td class="cell_model_img">
                    <img class="model_img" 
                         :src="$config.urlMain + 'images/models/' + item.index + '/' + item.model_fname" />
                </td>
                <td >
                    <div class="cell_model_name" title="Открыть стаистику" @click.stop="openStatModel(item)">{{ item.model_name }}</div>
                    <div>
                        {{ item.group1_name }} /
                        {{ item.group2_name }}
                    </div>
                </td>
                <td class="serialNumber" v-html="item.serial_number"></td>
                <td>{{ item.count_open }}</td>
                <td>{{ item.count_download_manual }}</td>
                <td>{{ item.count_download_technical_certificate }}</td>
                <td>{{ item.count_download_other_docs }}</td>
                <td>{{ item.createDateJsText }}</td>
            </tr>
        </table>
        
        <div class="cpanel_body_empty" v-if="list.length == 0">
            Список пуст
        </div>

        <div v-if="count_page > 1" class="pages">
            <span
                v-for="index in count_page"
                :key="index"
                :class="{'page_active': page + 1 == index}"
                @click.stop="page + 1 != index && getList(index - 1)">
                {{ index }}
            </span>
        </div>
        
    </div>
</template>

<script>
    import * as global from '../global.js'
    import Calendar from './Calendar.vue'
    
    export default {
        name: 'TableStats',
        emits: ["openStatFromModel"],
        components: {
            Calendar
        },
        data() {
            return {
                serach_value: '',
                list: [],
                page: 0,
                count_page: 0,
                sort: {
                    column: 0,
                    type: 0
                },
                periodDates: {
                    showCalendar_1: false,
                    showCalendar_2: false,
                    selectDate: '',
                    error: false,
                    start_date: '',
                    end_date: '',
                    indexCalendar: 0,
                },
            }
        },
        created() {
            this.SORT_MODEL__NAME                     = 1;
            this.SORT_SERIAL_NIMBER                   = 2;
            this.SORT_COLUMN__OPEN                    = 3;
            this.SORT_COLUMN__DOWNLOAD_MANUAL         = 4;
            this.SORT_COLUMN__TECHNICAL_CERTIFICATE   = 5;
            this.SORT_COLUMN__OTHER_DOCS              = 6;
            this.SORT_COLUMN__CREATED_AT              = 7;
            
            this.SORT_TYPE_ASC             = 1;
            this.SORT_TYPE_DESC            = 2;
            
            this.sort.column = this.SORT_COLUMN__OPEN;
            this.sort.type = this.SORT_TYPE_ASC;
            
            this.getList();
        },
        methods: {
            escapeRegExChars: function (value) {
                return value.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&");
            },
            clearSearch() {
                if(this.serach_value.length > 0) {
                    this.serach_value = '';
                    this.list = [];
                    this.getList();
                }
            },
            getList(_page = 0) {
                
                let searchValue = this.serach_value.toUpperCase();
                
                if( 
                           this.periodDates.start_date != ''
                        && this.periodDates.end_date != ''
                        && global.strToDate(this.periodDates.start_date).getTime() > global.strToDate(this.periodDates.end_date).getTime()
                ) {
                    this.periodDates.error = true;
                    return;
                }
                
                const startTms = global.strToDate(this.periodDates.start_date);
                const endTms   = global.strToDate(this.periodDates.end_date);
                
                this.page = _page;
                this.$bus.emit('showLoading');
                
                console.log(_page);

                this.$postRequest(this.$config.urlGetListStats,
                    {
                        serialNumber: searchValue,
                        page: _page,
                        sort_column: this.sort.column,
                        sort_type: this.sort.type,
                        startTms: ( startTms ? startTms.getTime() / 1000 : 0),
                        endTms: ( endTms ? endTms.getTime() / 1000 : 0),
                    }
                , localStorage.token)
                .then((data) => {
                    this.$bus.emit('hideLoading');
                    
                    let pattern = '(' + this.escapeRegExChars(searchValue) + ')';
                    
                    for(let item of data.list) {
                        let date = new Date(item.created_at * 1000);
                        let userTimezoneOffset = ( 3 * 60 + date.getTimezoneOffset()) * 60000;
                        item.createDateJs = new Date(date.getTime() - userTimezoneOffset);
                        item.createDateJsText = global.timeConverter(item.createDateJs.getTime() / 1000);
                        
                        item.serial_number_original = item.serial_number;
                        if( searchValue.length > 0 && item.serial_number.indexOf(searchValue) > -1 ) {
                            item.serial_number = item.serial_number.replace(new RegExp(pattern, 'gi'), '<strong>$1</strong>');
                        }
                    }
            
                    this.list = data.list;                    
                    this.count_page = data.count_page;
                })
                .catch(() => {
                    this.$bus.emit('hideLoading');
                });
            },
            changeSortColumn(columnSort) {
                if(this.sort.column === columnSort) {
                    if(this.sort.type === this.SORT_TYPE_ASC) {
                        this.sort.type = this.SORT_TYPE_DESC;
                    }
                    else {
                        this.sort.type = this.SORT_TYPE_ASC;
                    }
                }
                else {
                    this.sort.column = columnSort;
                    this.sort.type = this.SORT_TYPE_ASC;
                }
                
                this.getList();
            },
            openStatModel(item) {
                this.$emit("openStatFromModel", item);
            },
            openCalendar(index) {
                if(index == 1) {
                    this.periodDates.selectDate = this.periodDates.start_date;
                    this.periodDates.showCalendar_1  = ! this.periodDates.showCalendar_1;
                    this.periodDates.showCalendar_2  = false;
                }
                else {
                    this.periodDates.selectDate = this.periodDates.end_date;
                    this.periodDates.showCalendar_2  = ! this.periodDates.showCalendar_2;
                    this.periodDates.showCalendar_1  = false;
                }
                
                console.log(this.periodDates.selectDate);
                
                this.periodDates.indexCalendar = index;
            },
            setDateInCalendar(day_name, index) {
                if(index == 1) {
                    this.periodDates.start_date = day_name;
                }
                else {
                    this.periodDates.end_date = day_name;
                }
                
                this.periodDates.showCalendar_1 = false;
                this.periodDates.showCalendar_2 = false;
                this.periodDates.error = false;
                
                this.getList();
            },
            clearStartDate() {
                this.periodDates.start_date = '';
                this.periodDates.showCalendar_1 = false;
                this.periodDates.showCalendar_2 = false;
                this.periodDates.error = false;
                
                this.getList();
            },
            clearEndDate() {
                this.periodDates.end_date = '';
                this.periodDates.showCalendar_1 = false;
                this.periodDates.showCalendar_2 = false;
                this.periodDates.error = false;
                
                this.getList();
            },
        }
        
    }
</script>

<style>
    .serialNumber strong {
        color: #166adf;
    }
</style>
<style scoped>
    #table_stat {
        padding: 10px;
    }
    #tableStat {
        width: 100%;
        border: 1px solid #eee;
        border-collapse: collapse;
    }
    #tableStat td {
        vertical-align: middle;
        border: 1px solid #eee;
    }
    .tableStat_header > td {
        border-bottom: 1px solid #CCC;
        padding: 5px;
        font-size: 14px;
        white-space: nowrap;
    }
    .tableStat_row > td {
        padding: 5px;
        font-size: 14px;
    }
    .tableStat_row:hover > td {
        background: #f5f5f5;
    }
    .cell_model_img > img {
        max-width: 100px;
        max-height: 100px;
    }
    .cell_model_name {
        font-style: normal;
        font-size: 14px;
        color: #454A4D;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-height: 20px;        /* fallback */
        max-height: 40px;       /* fallback */
        margin-bottom: 5px;
    }
    .cell_model_name:hover {
        text-decoration: underline;
    }
    .pages {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 10px 0;
    }
    .pages > span {
        font-size: 14px;
        display: inline-block;
        padding: 2px 5px;
        cursor: pointer;
        margin: 0 2px;
    }
    .pages > .page_active {
        cursor: default;
        font-weight: bold;
    }
    .sort_icon {
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
        width: 16px;
        height: 16px;
        background-size: 16px 16px;
        background-repeat: no-repeat;
        cursor: pointer;
    }
    .sort_icon_up {
        background-image: url(/images/sort-up.svg);
    }
    .sort_icon_down {
        background-image: url(/images/sort-down.svg);
    }
    .sort_icon_none {
        background-image: url(/images/sort-none.svg);
    }
    
    .cpanel_body_empty {
        padding: 15px;
        font-size: 14px;
    }
    
    .table_stat_c_line {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    
    .table_stat_c_item {
        display: flex;
        align-items: center;
        margin-right: 10px;
        position: relative;
        padding: 5px 10px;
    }
    
    .table_stat_c_item_title {
        font-size: 14px;
        display: inline-block;
        margin-right: 10px;
    }
    .table_stat_c_periodDates {
        border: 1px solid #ccc;
        padding: 6px 10px;
        line-height: 17px;
        display: inline-block;
        background: #FFF;
        font-size: 14px;
        margin-right: 10px;
        position: relative;
        min-width: 116px;
    }
    
    .table_stat_c_periodDates_popupCalendar {
        position: absolute;
        top: 35px;
        left: -2px;
        border-radius: 10px;
        background: #FFF;
        border: 1px solid #C7D5E1;
        padding: 6px 10px;
        display: none;
        transition: opacity 1s ease-out;
        opacity: 0;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    }
    .table_stat_c_periodDates_popupCalendar-show {
        opacity: 1;
        display: block;
    }
    .table_stat_c_periodDates_clearBtn {
        width: 16px;
        height: 16px;
        position: absolute;
        right: 5px;
        top: 7px;
        cursor: pointer;
        opacity: 0.1;
    }
    .table_stat_c_periodDates_clearBtn:hover {
        opacity: 0.3;
    }
    .table_stat_c_periodDates_haveError > .table_stat_c_periodDates {
        border-color: red;
    }
    
    
    .inputWithClearBtn {
        position: relative;
    }
    .inputWithClearBtn > input {
        width: 100%;
        border: 1px solid #CCC;
        padding: 6px;
        font-size: 14px;
    }
    .inputWithClearBtn > input::-webkit-input-placeholder {text-transform: lowercase;}
    .inputWithClearBtn > input::-moz-placeholder {text-transform: lowercase;}
    .inputWithClearBtn > img {
        width: 16px;
        height: 16px;
        position: absolute;
        right: 5px;
        top: 7px;
        cursor: pointer;
        opacity: 0.1;
    }
    .inputWithClearBtn > img:hover {
        opacity: 0.3;
    }
    
    #searchBySerialNumber {
        width: 350px;
    }
    #searchBySerialNumber > input {
        text-transform: uppercase;
    }
    
</style>