<template>
    <div>
        
        <div class="table_stat_c_item">
            <span class="table_stat_c_item_title">Период отбора, по дате с: </span>
            <div :class="{'table_stat_c_periodDates_haveError': periodDates.error }">
                <span class="table_stat_c_periodDates" @click.stop="openCalendar(1)">
                    {{ periodDates.start_date == '' ? '__.__.____' : periodDates.start_date }}
                    <img src="/images/clear-search-input.svg" 
                         class="table_stat_c_periodDates_clearBtn"
                         @click.stop="clearStartDate" />
                    <div class="table_stat_c_periodDates_popupCalendar"
                        :class="{'table_stat_c_periodDates_popupCalendar-show': periodDates.showCalendar_1 }">
                       <Calendar v-if="periodDates.showCalendar_1" 
                                 :selectDate="periodDates.selectDate" 
                                 :indexField="periodDates.indexCalendar" 
                                 @selectDay="setDateInCalendar" />
                   </div>
                </span>
                <span class="table_stat_c_item_title"> до: </span>
                <span class="table_stat_c_periodDates" @click.stop="openCalendar(2)">
                    {{ periodDates.end_date == '' ? '__.__.____' : periodDates.end_date }}
                    <img src="/images/clear-search-input.svg" 
                         class="table_stat_c_periodDates_clearBtn"
                         @click.stop="clearEndDate" />
                    <div class="table_stat_c_periodDates_popupCalendar"
                        :class="{'table_stat_c_periodDates_popupCalendar-show': periodDates.showCalendar_2 }">
                       <Calendar v-if="periodDates.showCalendar_2" 
                                 :selectDate="periodDates.selectDate" 
                                 :indexField="periodDates.indexCalendar" 
                                 @selectDay="setDateInCalendar" />
                   </div>
                </span>
            </div>
        </div>
        
        <template v-if="list.length > 0">
            
            <table class="page_2_listTable">
                <tr class="page_2_listTable_header">
                    <td>Имя</td>
                    <td>Фамилия</td>
                    <td>Название организации</td>
                    <td>Тип аккаунта</td>
                    <td>Кол-во моделей общее</td>
                    <td>Кол-во QR-кодов</td>
                    <td>Аккаунт создан</td>
                </tr>
                <tr
                    v-for="(item, index) in list"
                    :key="index"
                    class="page_2_listTable_row"
                    >
                    <td class="cell_firstName">{{ item.first_name }}</td>
                    <td class="cell_lastName">{{ item.last_name }}</td>
                    <td class="cell_lastName">{{ item.org_name }}</td>
                    <td class="cell_email">{{ item.type_account == 1 ? "Дистрибьютор" : "Производитель" }}</td>
                    <td class="cell_inn">{{ item.count_models }}</td>
                    <td class="cell_inn">{{ item.count_serialnumbers }}</td>
                    <td class="cell_inn">{{ item.createDateJsText }}</td>
                </tr>
            </table>
        </template>

        <div class="cpanel_body_empty" v-if="list.length == 0">
            Список пуст
        </div>
        
        <div v-if="count_page > 1" class="pages">
            <span
                v-for="index in count_page"
                :key="index"
                :class="{'page_active': page + 1 == index}"
                @click.stop="page + 1 != index && loadList(index - 1)">
                {{ index }}
            </span>
        </div>
        
    </div>
</template>

<script>
    import * as global from '../global.js'
    import Calendar from './Calendar.vue'
    
    export default {
        name: 'ShowGlobalStatForAccounts',
        components: {
            Calendar
        },
        data() {
            return {
                list: [],
                page: 0,
                count_page: 0,
                periodDates: {
                    showCalendar_1: false,
                    showCalendar_2: false,
                    selectDate: '',
                    error: false,
                    start_date: '',
                    end_date: '',
                    indexCalendar: 0,
                },
            }
        },
        created() {
            this.loadList();
        },
        methods: {
            loadList(page = 0) {
                
                if( 
                           this.periodDates.start_date != ''
                        && this.periodDates.end_date != ''
                        && global.strToDate(this.periodDates.start_date).getTime() > global.strToDate(this.periodDates.end_date).getTime()
                ) {
                    this.periodDates.error = true;
                    return;
                }
                
                const startTms = global.strToDate(this.periodDates.start_date);
                const endTms   = global.strToDate(this.periodDates.end_date);
                
                this.page = page;
                this.$getRequest(this.$config.urlGetGlobalStats 
                        + this.page
                        + '/'
                        + ( startTms ? startTms.getTime() / 1000 : 0)
                        + '/'
                        + ( endTms ? endTms.getTime() / 1000 : 0)
                    , localStorage.token)
                    .then((data) => {
                        this.$bus.emit('hideLoading');
                
                        for(let item of data.list) {
                            let date = global.convertDateTime(item.created_at);
                            let userTimezoneOffset = ( 3 * 60 + date.getTimezoneOffset()) * 60000;
                            item.createDateJs = new Date(date.getTime() - userTimezoneOffset);
                            item.createDateJsText = global.timeConverter(item.createDateJs.getTime() / 1000);
                        }
                
                        this.list = data.list;
                        
                        this.count_page = data.count_page;
                    })
                    .catch(() => {
                        this.$bus.emit('hideLoading');
                    });
                
            },
            openCalendar(index) {
                if(index == 1) {
                    this.periodDates.selectDate = this.periodDates.start_date;
                    this.periodDates.showCalendar_1  = ! this.periodDates.showCalendar_1;
                    this.periodDates.showCalendar_2  = false;
                }
                else {
                    this.periodDates.selectDate = this.periodDates.end_date;
                    this.periodDates.showCalendar_2  = ! this.periodDates.showCalendar_2;
                    this.periodDates.showCalendar_1  = false;
                }
                
                console.log(this.periodDates.selectDate);
                
                this.periodDates.indexCalendar = index;
            },
            setDateInCalendar(day_name, index) {
                if(index == 1) {
                    this.periodDates.start_date = day_name;
                }
                else {
                    this.periodDates.end_date = day_name;
                }
                
                this.periodDates.showCalendar_1 = false;
                this.periodDates.showCalendar_2 = false;
                this.periodDates.error = false;
                
                this.loadList();
            },
            clearStartDate() {
                this.periodDates.start_date = '';
                this.periodDates.showCalendar_1 = false;
                this.periodDates.showCalendar_2 = false;
                this.periodDates.error = false;
                
                this.loadList();
            },
            clearEndDate() {
                this.periodDates.end_date = '';
                this.periodDates.showCalendar_1 = false;
                this.periodDates.showCalendar_2 = false;
                this.periodDates.error = false;
                
                this.loadList();
            },
        }
    }
</script>

<style scoped>
    .page_2_listTable {
        width: 100%;
    }
    .page_2_listTable_header > td {
        border-bottom: 1px solid #CCC;
        padding: 5px;
        font-size: 14px;
    }
    .page_2_listTable_row > td {
        padding: 5px;
        font-size: 14px;
    }
    .page_2_listTable_row:hover > td {
        background: #f5f5f5;
    }
    .page_2_listTable > tr > td:first-child {
        padding-left: 12px;
    }
    
    .pages {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 10px 0;
    }
    .pages > span {
        font-size: 14px;
        display: inline-block;
        padding: 2px 5px;
        cursor: pointer;
        margin: 0 2px;
    }
    .pages > .page_active {
        cursor: default;
        font-weight: bold;
    }
    
    .cpanel_body_empty {
        padding: 15px;
        font-size: 14px;
    }
    
    .table_stat_c_line {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    
    .table_stat_c_item {
        display: inline-flex;
        align-items: center;
        margin-right: 10px;
        position: relative;
        padding: 5px 10px;
    }
    
    .table_stat_c_item_title {
        font-size: 14px;
        display: inline-block;
        margin-right: 10px;
    }
    .table_stat_c_periodDates {
        border: 1px solid #ccc;
        padding: 6px 10px;
        line-height: 17px;
        display: inline-block;
        background: #FFF;
        font-size: 14px;
        margin-right: 10px;
        position: relative;
        min-width: 116px;
    }
    
    .table_stat_c_periodDates_popupCalendar {
        position: absolute;
        top: 35px;
        left: -2px;
        border-radius: 10px;
        background: #FFF;
        border: 1px solid #C7D5E1;
        padding: 6px 10px;
        display: none;
        transition: opacity 1s ease-out;
        opacity: 0;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    }
    .table_stat_c_periodDates_popupCalendar-show {
        opacity: 1;
        display: block;
    }
    .table_stat_c_periodDates_clearBtn {
        width: 16px;
        height: 16px;
        position: absolute;
        right: 5px;
        top: 7px;
        cursor: pointer;
        opacity: 0.1;
    }
    .table_stat_c_periodDates_clearBtn:hover {
        opacity: 0.3;
    }
    .table_stat_c_periodDates_haveError > .table_stat_c_periodDates {
        border-color: red;
    }
    
</style>