<template>
    <div id="stat_download_technical_certificate">
        <div id="stat_download_technical_certificate_header">
            <div>
                <div id="stat_download_technical_certificate_headerTitle">Статистика загрузки технического паспорта</div>
                <div id="stat_download_technical_certificate_headerModelName" v-if="modelInfo != null">{{ modelInfo.model_name }}</div>
            </div>
            <button class="btn" @click.stop="close()">Закрыть</button>
        </div>
    </div>
    <div id="stat_download_technical_certificateBody">
            <template v-if="list.length > 0">
                <table class="page_2_listTable">
                    <tr class="page_2_listTable_header">
                        <td>Имя</td>
                        <td>Фамилия</td>
                        <td>Email</td>
                        <td>Телефон</td>
                        <td>Должность</td>
                        <td>Наименование объекта</td>
                        <td>Дата и время события</td>
                    </tr>
                    <tr
                        v-for="(item, index) in list"
                        :key="index"
                        class="page_2_listTable_row"
                        >
                        <td class="cell_firstName">{{ item.userInfo.first_name }}</td>
                        <td class="cell_lastName">{{ item.userInfo.last_name }}</td>
                        <td class="cell_email">{{ item.userInfo.email }}</td>
                        <td class="cell_phone">{{ item.userInfo.phone }}</td>
                        <td class="cell_inn">{{ item.userInfo.position }}</td>
                        <td class="cell_inn">{{ item.userInfo.object_name }}</td>
                        <td class="cell_datetime">{{ item.createDateJsText }}</td>
                    </tr>
                </table>
            </template>

            <div class="cpanel_body_empty" v-if="list.length == 0">
                Список пуст
            </div>

            <div v-if="count_page > 1" class="pages">
                <span
                    v-for="index in count_page"
                    :key="index"
                    :class="{'page_active': page + 1 == index}"
                    @click.stop="page + 1 != index && loadList(index - 1)">
                    {{ index }}
                </span>
            </div>
    </div>
</template>

<script>
    import * as global from '../global.js'
    
    export default {
        name: 'StatDownloadTechnicalCertificate',
        emits: ["close"],
        data() {
            return {
                list: [],
                listUsers: [],
                page: 0,
                count_page: 0,
                modelInfo: null
            }
        },
        methods: {
            close() {
                this.$emit("close");
            },
            setModelInfo(data) {
                this.modelInfo = data;
                
                console.log(data);
            },
            loadList(page = 0) {
                this.page = page;
                this.$bus.emit('showLoading');

                this.$getRequest(this.$config.urlGetListStatsDownloadTechnicalCertificate + this.modelInfo.model_id + '/' + this.page
                , localStorage.token)
                .then((data) => {
                    this.$bus.emit('hideLoading');
                    
                    for(let item of data.list) {
                        let date = new Date(item.created_at);
                        let userTimezoneOffset = ( 3 * 60 + date.getTimezoneOffset()) * 60000;
                        item.createDateJs = new Date(date.getTime() - userTimezoneOffset);
                        item.createDateJsText = global.timeConverter(item.createDateJs.getTime() / 1000);
                    }
                    
                    this.listUsers = data.listUsers;
                    this.list = this.buildList(data.list);
                    
                    this.count_page = data.count_page;
                })
                .catch(() => {
                    this.$bus.emit('hideLoading');
                });
            },
            getUserInfo(user3_id) {
                for(let item of this.listUsers) {
                    if(item.user3_id === user3_id) {
                        return item;
                    }
                }
                
                return null;
            },
            buildList(list) {
                let result = [];
                
                for(let item of list) {
                    let row = {
                       createDateJsText: item.createDateJsText,
                       userInfo: this.getUserInfo(item.user3_id)
                    };
                    
                    result.push(row);
                }
                
                return result;
            }
        }
    }
</script>

<style scoped>
    #stat_download_technical_certificate_header {
        display: flex;
        align-items: center;
        font-size: 14px;
        padding: 10px;
        border-bottom: 1px solid #f6f6f6;
    }
    #stat_download_technical_certificate_header > div {
        flex-grow: 1;
    }
    #stat_download_technical_certificate_header > .btn {
        font-size: 14px;
        border: 1px solid #CCC;
        border-radius: 5px;
        padding: 3px 10px;
    }
    #stat_download_technical_certificate_header > .btn:active {
        padding: 4px 9px 2px 11px;
    }
    #stat_download_technical_certificate_headerTitle {
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
    }
    #stat_download_technical_certificate_headerModelName {
        font-style: normal;
        font-size: 12px;
        color: #454A4D;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-height: 14px;        /* fallback */
        max-height: 40px;       /* fallback */
        margin-bottom: 5px;
    }
    .page_2_listTable {
        width: 100%;
    }
    .page_2_listTable_header > td {
        border-bottom: 1px solid #CCC;
        padding: 5px;
        font-size: 14px;
    }
    .page_2_listTable_row > td {
        padding: 5px;
        font-size: 14px;
    }
    .page_2_listTable_row:hover > td {
        background: #f5f5f5;
    }
    .page_2_listTable > tr > td:first-child {
        padding-left: 12px;
    }
    
    .pages {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 10px 0;
    }
    .pages > span {
        font-size: 14px;
        display: inline-block;
        padding: 2px 5px;
        cursor: pointer;
        margin: 0 2px;
    }
    .pages > .page_active {
        cursor: default;
        font-weight: bold;
    }
    
    .cpanel_body_empty {
        padding: 15px;
        font-size: 14px;
    }
</style>