<template>
  <div id="login">
      <div id="login_form">
          <div id="login_form_header">Авторизация</div>
          <div>
              <div class="login_form_label">Логин</div>
              <div class="login_form_input">
                  <input v-mask="'+7(###) ### ## ##'"
                         v-model="form.phone"
                         :class="{'form_login_error_input' : form.errorPhone }"/>
              </div>
              <div class="login_form_label">Пароль</div>
              <div class="login_form_input">
                  <input type="password"
                         v-model="form.pass"
                         :class="{'form_login_error_input' : form.errorPass }"/>
              </div>
          </div>
          <div id="login_form_footer">
              <button class="btn" @click.stop="loginAdmin()">Войти</button>
          </div>
      </div>
  </div>
</template>

<script>
import router from '@/router'
import * as global from '../global.js'
    
export default {
    name: 'Login',
    data() {
        return {
            showContent: false,
            isAuthorized: false,
            form: {
                errorPhone: false,
                errorPass: false,
                phone: '',
                pass: '',
            }
        };
    },
    created() {
        document.title = "Авторизация";
        
    },
    methods: {
        loginAdmin() {
            this.form.errorPhone = false;
            this.form.errorPass = false;
            
            console.log(this.form.phone, global.validatePhone(this.form.phone) );
            
            if( ! global.validatePhone(this.form.phone)) {
                this.form.errorPhone = true;
                return;
            }
            
            if(this.form.pass.length < 5) {
                this.form.errorPass = true;
                return;
            }
            
            this.$bus.emit('showLoading');
            
            this.$postRequest(this.$config.urlLogin, {
                login: this.form.phone,
                pass: this.form.pass
            })
            .then((data) => {
                this.$bus.emit('hideLoading');
                localStorage.token = data.token;
                router.push('/cpanel');
            })
            .catch(() => {
                this.$bus.emit('hideLoading');
            });
        }
    }
}
</script>

<style scoped>
    #login {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #eef;
    }
    #login_form {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        background: #FFFFFF;
        box-shadow: 0px 20px 30px rgba(1, 101, 165, 0.07);
        border-radius: 7px;
        padding: 20px;
        min-width: 300px;
    }
    #login_form_header {
        margin-bottom: 20px;
        font-weight: bold;
    }
    .login_form_label {
        margin-bottom: 4px;
        color: #555;
    }
    .login_form_input {
        margin-bottom: 12px;
       
    }
    .login_form_input > input {
        font-size: 14px;
        width: 100%;
        padding: 6px;
        border: 1px solid #CCC;
        border-radius: 3px;
    }
    #login_form_footer {
        display: flex;
        justify-content: flex-end;
    }
    .btn {
        color: #333;
        padding: 6px 14px;
        background: #eeeeee; /* Old browsers */
        background: -moz-linear-gradient(top,  #eeeeee 0%, #eeeeee 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  #eeeeee 0%,#eeeeee 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  #eeeeee 0%,#eeeeee 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#eeeeee',GradientType=0 ); /* IE6-9 */
        border: 1px solid #CCC;
        border: none;
        border-radius: 3px;
    }
    .btn:hover {
        color: #000;
    }
    .btn:active {
        background: #cccccc;
        background: -moz-linear-gradient(top,  #cccccc 0%, #eeeeee 100%);
        background: -webkit-linear-gradient(top,  #cccccc 0%,#eeeeee 100%);
        background: linear-gradient(to bottom,  #cccccc 0%,#eeeeee 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cccccc', endColorstr='#eeeeee',GradientType=0 );
    }
    .form_login_error_input {
        border-color: red !important;
    }
</style>
